body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 64px);
  position: relative;
}


@media only screen and (max-device-width: 900px) {
  .parallax {
    background-attachment: scroll;
  }
}

.shadow {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 2.5rem;
  text-shadow: 0 0 10px black;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
}

.banner {
  position: absolute;
  bottom: 60px;
  left: 70px;
  text-align: left;
}
.title {
  font-size: 0.8rem;
  font-weight: 500;
  text-shadow: 0 0 10px black;
}
.subtitle {
  font-size: 2.5rem;
  font-weight: 400;
  text-shadow: 0 0 10px black;
}

@media only screen and (max-device-width: 900px) {
  .banner {
    bottom: 30px;
    left: 30px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;